<div id="wrapper">

    <app-topbar (settingsButtonClicked)="onSettingsButtonClicked()" (mobileMenuButtonClicked)="onToggleMobileMenu()">
    </app-topbar>

    <app-leftsidebar [sidebarType]="sidebarType"></app-leftsidebar>

    <div class="content-page">
        <div class="content">
            <!-- content -->
            <router-outlet></router-outlet>
        </div>

        <!-- footer -->
        <app-footer></app-footer>
    </div>
</div>
