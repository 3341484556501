<!-- ========== Left Sidebar Start ========== -->

<!--- Sidemenu -->

<ul class="metismenu" id="side-menu" #sideMenu>
  <ng-container *ngFor="let item of menuItems">
    <li class="side-nav-title side-nav-item menu-title" *ngIf="item.isTitle">{{ item.label }}</li>

    <li *ngIf="!item.isTitle" >

      <a *ngIf="hasItems(item)" href="javascript:void(0);" class="side-nav-link">
        <i [attr.data-feather]="item.icon" appFeatherIcon *ngIf="item.icon"></i>
        <span> {{ item.label }}</span>
        <span class="menu-arrow"></span>
      </a>

      <a [routerLink]="item.link" *ngIf="!hasItems(item)" class="side-nav-link side-nav-link-ref"
        [attr.data-parent]="item.parentId">
        <i [attr.data-feather]="item.icon" appFeatherIcon *ngIf="item.icon"></i>
        <span> {{ item.label }}</span>
        <span class="badge badge-{{item.badge.variant}} float-right" *ngIf="item.badge">{{item.badge.text}}</span>
      </a>

      <ul *ngIf="hasItems(item)" class="nav-second-level" aria-expanded="false">

        <li *ngFor="let subitem of item.subItems" [ngClass]="{'side-nav-item': hasItems(subitem)}">
          <a *ngIf="hasItems(subitem)" href="javascript:void(0);" class="side-nav-link-a-ref">
            <i [attr.data-feather]="subitem.icon" appFeatherIcon *ngIf="subitem.icon"></i>

            {{ subitem.label }}
            <span class="menu-arrow"></span>
          </a>

          <a [routerLink]="subitem.link" *ngIf="!hasItems(subitem)" class="side-nav-link-ref"
            [attr.data-parent]="subitem.parentId">
            <i [attr.data-feather]="subitem.icon" appFeatherIcon *ngIf="subitem.icon"></i>
            {{ subitem.label }}
          </a>

          <ul *ngIf="hasItems(subitem)" class="nav-third-level" aria-expanded="false">
            <li *ngFor="let subSubitem of subitem.subItems">
              <a [routerLink]="subSubitem.link" [routerLinkActive]="['active']" class="side-nav-link-ref"
                [attr.data-parent]="subSubitem.parentId">
                <i [attr.data-feather]="subitem.icon" appFeatherIcon *ngIf="subitem.icon"></i>
                {{ subSubitem.label }}
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </li>
  </ng-container>
</ul>
