<!-- Right Sidebar -->
<div class="right-bar" (clickOutside)="hide()" [excludeBeforeClick]="true" [exclude]="'.right-bar-toggle'">
  <div class="rightbar-title">
    <a href="javascript:void(0);" class="right-bar-toggle float-right" (click)="hide();">
      <i data-feather="x-circle" appFeatherIcon></i>
    </a>
    <h5 class="m-0">User Activity</h5>
  </div>

  <div class="slimscroll-menu" appSlimScroll>
   <!-- <div class=chat-panel>
      <div class=chat-inner>
        <div class=chat-users>
          <div class=nav-justified-xs>
            <ul class="nav nav-tabs nav-justified">
              <li class=active> <a href=#activity data-toggle=tab>Activity</a> </li>
              <li> <a href=#chat-list data-toggle=tab>Chat</a> </li>
              <li> <a href=#market data-toggle=tab>Favorites</a> </li>
            </ul>
          </div>
          <div class=tab-content style="padding:20px 10px;">
            <div class="tab-pane" id=chat-list>
            </div>
            <div class=tab-pane id=market>
            </div>
            <div class="tab-pane active" id=activity> -->
              <p-timeline [value]="activityList" >
                <ng-template pTemplate="content" let-event>
                  {{event.status}}
                </ng-template>
                <ng-template pTemplate="opposite" let-event>
                  <small class="p-text-secondary">{{event.date}}</small>
                </ng-template>
              </p-timeline>
            <!-- </div>
          </div>
        </div>
      </div>
    </div> -->
  </div> <!-- end slimscroll-menu-->
</div>
<!-- /Right-bar -->

<!-- Right bar overlay-->
<div class="rightbar-overlay"></div>
